<template>
  <section class="favorite-products">
    <b-container v-if="loading">
      <b-row>
        <div class="spinner-area">
          <b-spinner />
        </div>
      </b-row>
    </b-container>

    <b-container v-if="!loading && products.length > 0">
      <b-row class="mb-4">
        <b-col
          sm="6"
          lg="3"
          class="mb-3"
        >
          <div class="bg-column">
            <v-select
              v-model="columnOrder"
              :options="orderOptions"
              variant="custom"
              item-text="text"
              item-value="value"
              placeholder="Ordenar"
              label="text"
              @input="handleGetFavoriteProducts"
            />
          </div>
        </b-col>

        <b-col
          sm="6"
          lg="4"
          class="mb-3"
        >
          <b-form-input
            id="reset-password-new"
            v-model="productDescription"
            class="form-control-merge custom-filter-input"
            placeholder="Nome do Produto"
            autocomplete="off"
          />
        </b-col>

        <b-col
          sm="12"
          lg="5"
          class="mb-3"
        >
          <button-form
            button-class="me-3 mb-2"
            :height="40"
            :line-height="37"
            @action="handleGetFavoriteProducts"
          >
            <feather-icon
              class="me-1"
              icon="SearchIcon"
            />
            Buscar
          </button-form>

          <button-form
            component-mode="outline"
            :height="40"
            :line-height="37"
            @action="clearFilters"
          >
            <feather-icon
              class="me-1"
              icon="FilterIcon"
            />
            Limpar
          </button-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="(item, index) in products"
          :key="index"
          sm="12"
          md="6"
          lg="4"
          class="mb-4"
        >
          <product-card
            :product-image="item.imagePath"
            :product-description="item.product_description"
            :product-unique-name="item.product_unique_name"
            text-button="Detalhes"
            :second-button="true"
            text-second-button="Remover"
            @actionSecondButton="handleRemoveFavoriteIcon(item.id)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="mb-4"
        >
          <div class="text-placeholder-color mb-0">
            Mostrando {{ paginationData.fromLine }}
            a {{ paginationData.toLine }}
            de {{ paginationData.totalLines }} resultados.
          </div>
        </b-col>

        <b-col
          cols="12"
        >
          <Pagination
            :pagination="paginationData"
            @pageClicked="updateCurrentPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="products.length === 0">
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          Nenhum registro encontrado
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import ProductCard from '@/components/ProductCard/ProductCard.vue'
import vSelect from 'vue-select'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import { getAllUserFavoriteProducts, removeFavoriteIcon } from '@/utils/requests/userFavoriteProducts'
import { successMessageToast, warningMessageToast } from '@/libs/sweetalerts'
import { messages } from '@/utils/validations/messages'

export default {
  name: 'UserFavoriteProducts',

  components: {
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    vSelect,
    Pagination,
    ProductCard,
    ButtonForm,
  },

  data() {
    return {
      loading: false,

      disabledButton: false,

      orderOptions: [
        { value: 'asc', text: 'A-Z' },
        { value: 'desc', text: 'Z-A' },
      ],

      columnOrder: null,
      productDescription: '',

      products: [],

      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 12,
      },
    }
  },

  mounted() {
    this.handleGetFavoriteProducts()
  },

  methods: {
    async handleGetFavoriteProducts() {
      this.loading = true

      this.products = []

      await getAllUserFavoriteProducts(this.setParams())
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.products = response.data.data
              this.handlePagination(response.data)
            }
          }
        })

      this.loading = false
    },

    async handleRemoveFavoriteIcon(productId) {
      this.disabledButton = true

      await removeFavoriteIcon(productId)
        .then(response => {
          if (response.status === 204) {
            this.handleGetFavoriteProducts()
          }
        })
        .catch(() => {
          warningMessageToast(messages.impossible)
        })

      this.disabledButton = false
    },

    clearFilters() {
      this.columnOrder = null
      this.productDescription = ''

      this.handleGetFavoriteProducts()
    },

    setParams() {
      return {
        perPage: this.paginationData.defaultSize,
        page: this.paginationData.currentPage,
        columnOrder: this.columnOrder ? this.columnOrder.value : null,
        productDescription: this.productDescription,
      }
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.handleGetFavoriteProducts()
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
